// global style sheet
@import '@assets/scss/variables.scss';

:root {
  // body properties
  --i-body-bg: #{$body-bg};
  --i-body-text: #{$body-text};
  --bs-body-font-size: 0.88rem!important;
  --bs-body-line-height: 1.125rem!important;
  --bs-secondary-bg: var(--i-dark)!important;
  --bs-nav-link-font-size: 0.88rem!important;

  --i-sidebar-text : #{$sidebar-text};
  --i-sidebar-bg: #{$sidebar-bg};

  --i-netural-grey: #{$netural-grey};

  // primary colors
  --i-primary: #{$primary};

  // secondary colors
  --i-secondary: #{$secondary};
  --i-secondary-light: #{$secondary-light};

  // highlight colors
  --i-success: #{$success};
  --i-success-light: #{$success-light};
  --i-danger: #{$danger};
  --i-danger-light: #{$danger-light};
  --i-message: #{$message};
  --i-info: #{$info};
  --i-light: #{$light};
  --i-warning: #{$warning};
  --i-warning-message: #{$warning-background};
  --i-white: #{$white};
  --i-black: #{$black};
  --i-dark: #{$dark};

  --i-border-color: #{$border-color};

  // fonts
  --i-font: 'Satoshi Variable';
}

@font-face {
  font-family: 'Satoshi Variable';
  src: url(/assets/fonts/Satoshi-Variable.ttf);
}

html {
  font-size: 16px;
}

body {
  @extend .text__regular;
  background-color: var(--i-body-bg);
  color: var(--i-body-text)!important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .text__bold;
}

.text {
  &__regular {
    font-family: var(--i-font), sans-serif !important;
    font-weight: 400;
  }
  &__medium {
    font-family: var(--i-font), sans-serif;
    font-weight: 500;
  }
  &__bold {
    font-family: var(--i-font), sans-serif;
    font-weight: 700;
  }

  &__ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.line__clamp {
  &--2,
  &--3 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    box-orient: vertical;
  }

  &--2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }

  &--3 {
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}

.bg {
  &__primary {
    background-color: var(--i-primary) !important;
  }
  &__secondary {
    background-color: var(--i-secondary) !important;
  }
}

.fs__ {
  &12 {
    font-size: 0.75rem!important;
    line-height: 1rem!important;
  }
  &14 {
    font-size: 0.88rem!important;
    line-height: 1.125rem!important;
  }
  &16 {
    font-size: 1rem!important;
    line-height: 1.375rem!important;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.btn {
  --bs-btn-font-family:  var(--i-font), sans-serif !important;
  --bs-btn-font-weight: 700!important;
  --bs-btn-line-height: 1.38rem!important;
}

pre, code {
  font-family:  var(--i-font), sans-serif;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  cursor: not-allowed;
}

.form-select {
  cursor: pointer;
}

.form-control {
  @extend .fs__14;
  padding: 0.63rem 1rem!important;
  &:disabled, &.disabled {
    cursor: not-allowed;
  }
  &::placeholder { 
    background-color: var(--i-dark);
    color: var(--i-body-text);
  }
  &.ng-touched.ng-invalid, &:required {
    border-color: var(--i-danger);
  }
}

.btn-primary {
  background: transparent linear-gradient(180deg, #0071D8 0%, #1D3150 100%) 0% 0% no-repeat padding-box;
  --bs-btn-border-color: none!important;
  &:hover {
    background: var(--i-primary);
  }
}

.ng-select {
  .ng-select-container {
    border-radius: var(--bs-border-radius);
  }
  &.ng-select-single .ng-select-container {
    padding: 0.55rem 0.75rem;
    height: 42px;
    .ng-value-container .ng-input {
      top: 0.55rem;
      padding-left: 1.25rem;
    }
  }
  &.ng-select--sm.ng-select.ng-select-single .ng-select-container {
    padding: 0.2rem 0.35rem;
    height: 26px;
  }
  &.ng-select-multiple .ng-select-container {
    padding: 0.25rem 0.75rem;
    .ng-value-container .ng-placeholder {
      top: 7px;
    }
  }

  &.ng-select-opened.ng-select-bottom > .ng-select-container {
    border-radius: var(--bs-border-radius);
  }

  .ng-clear-wrapper {
    top: 1px;
    color: var(--i-secondary-text);
  }
}

.ng-dropdown-panel {
  &.ng-select-bottom {
    top: 100%;
    border-radius: var(--bs-border-radius);
    margin-top: -4px;
    width: calc(100% - 20px);
    border: none;
    box-shadow: 1px 1px 5px #cfcfcf;
    margin-left: 0.62rem;
  }

  .ng-dropdown-panel-items .ng-option.ng-option-selected,
  .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    color: var(--i-secondary-text);
    background-color: var(--i-secondary-shade-1);
  }
}

.i-modal.modal,
.i-modal {
  .modal-footer {
    border-top: none;
  }
}
.modal-content {
  border: none!important;
}

// @Start Package related classes that need higher level overriding
.sidebar {
  .panel {
    .panel-heading.card-header {
      border-bottom: 1px solid var(--i-border-color);
      background: none;
      .panel-title > .accordion-toggle > button {
        color: var(--i-sidebar-text);
        text-decoration: none;
        padding: 0;
        text-align: left;    
      }
      .panel-title {
        .accordion-toggle {
          display: inline-block;
          width: 90%;
        }
        &::after {
          display: inline-block;
          content: url("@assets/images/arrow-down-white.svg");
          transform: rotate(0deg);
          transition: all ease .2s;
          vertical-align: baseline;
        }
      }

    } 
    &.panel-open .panel-heading.card-header .panel-title::after {
      transform: rotate(-180deg);
      vertical-align: sub;
    }
    
    .panel.card {
      border-radius: 0;
      background: none;
      border: 0;
    }

    .panel-collapse > .panel-body {
      padding: 0;
    }
  }

  .favourite-subscription {
    .panel .panel-heading.card-header .panel-title .accordion-toggle {
      width: 70%;
    }

    .panel .panel-heading.card-header .panel-title::before {
          display: inline-block;
          transition: all ease .2s;
          vertical-align: middle;
          margin-right: 0.94rem;
          width: 15px;
          height: 20px;
    }

    .favourite {
      .panel .panel-heading.card-header .panel-title::before {
        content: url("@assets/images/bookmark.svg");
      }
    }

    .subscription {
      .panel .panel-heading.card-header .panel-title::before {
        content: url("@assets/images/installment-invoice.svg");
      }
    }
  }
} 

.body-section .tab-container {
  ul {
    padding: 0 2rem;
  }
  .nav.nav-tabs {
    border-top: none!important;
    .nav-item .nav-link {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      .heading {
        border-right: none;
      }
    } 
  }
} 

.body-section .request-response-section .tab-container {
  .tab-content > .tab-pane {
    margin: 0.5rem 0;
  }
  ul {
    padding: 0;
  }
  .nav.nav-tabs{
    border-top: 1px solid var(--i-border-color)!important;
    .nav-item .nav-link {
      margin-left: 0;
      margin-right: 0;
      .heading {
        border-right: 1px solid var(--i-border-color);
      }
    } 
  }
} 

.tab-container .nav.nav-tabs {
  border-top: 1px solid var(--i-border-color);
  border-bottom: 1px solid var(--i-border-color);
  .nav-item {
    &:last-of-type .nav-link .heading {
      border-right: none!important;
    }
    .nav-link{
      padding: 0.875rem 0 0.69rem;
      border-radius: 5px;
      .heading {
        padding: 0 0.5rem 0 1rem;
        border-right: 1px solid var(--i-border-color);
        color: var(--i-body-text);
      }
      &.active {
        border: none;
        border-bottom: 5px solid var(--i-white);
        margin-bottom: -3px;
      }
    }

    .nav-link .heading {
      &::before {
        display: inline-block;
        margin-right: 0.62rem;
        vertical-align: middle;
      }
      .info {
        vertical-align: middle;
      }
    }

    #authorization-link .heading::before {
      content: url("@assets/images/ssl-certificate.svg");
    }

    #params-link .heading::before {
      content: url("@assets/images/settings.svg");
    }

    #headers-link .heading::before {
      content: url("@assets/images/header.svg");
    }

    #body-link .heading::before {
      content: url("@assets/images/web-programming.svg");
    }
  }
}

.nav-tabs {
  --bs-nav-tabs-border-width: 0!important;
}

// @Ends Package related classes that need higher level overriding

.no-example {
  @extend .fs__14;
  @extend .text__regular;
  color: var(--i-message);
  background-color: var(--i-warning-message);
  border-radius: 10px;
  &::before {
    display: inline-block;
    content: url("@assets/images/warning.svg");
    vertical-align: middle;
    margin-right: 1rem;
  }
}

.info {
  background: none;
  border: none;
  vertical-align: text-top;

  &::after {
    display: inline-block;
    content: url("@assets/images/information.svg");
  }
}

// @Starts request & response sections
.vertical-align-sub {
  vertical-align: sub;
}

.tablet-element {
  border: 1px solid var(--i-border-color);
  padding: 0.63rem 1rem;
  border-radius: 8px;
}

.content-type, .example {
  width: fit-content;
  &.list::after {
      display: inline-block;
      content: url("@assets/images/arrow-down-white.svg");
      transform: rotate(0deg);
      transition: all ease 0.2s;
      vertical-align: baseline;
      margin-left: 0.5rem;
  }
}

.dropdown {
  width: fit-content;
  &.open {
      .content-type, .example {
          &.list::after {
              transform: rotate(-180deg);
          }
      }
  }
}

.dropdown-menu{
  width: -webkit-fill-available;
  li {
      font-size: 0.88rem;
      line-height: 1.18rem;
      white-space: break-spaces;
      border-bottom: 1px solid var(--i-border-color);
      border-radius: 0;
      &:hover {
          background-color: var(--i-border-color);
          color: var(--i-black);
          @extend .text__medium;
      }
  }
}

table.param-table {
  th, input {
      padding: 0.5rem 1rem;
  }
  th, td {
      border: 1px solid var(--i-border-color);
  }
}

// @Ends For request & response sections

// @Starts Modal popup section
.modal-wrapper table {
  thead, tbody, tfoot, tr, td, th {
    border-width: 1px;
    padding: 0.3rem;
  }
}
// @Ends Modal popup section

.no-content-centered {
  padding: 2.5rem;
  color: var(--i-message);
  font-size: 1rem;
  font-weight: 600;
  background-color: var(--i-warning-message);
  border: 1px solid #ccc;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.badge {
  padding: 4px 20px;
  border-radius: 4px;
  background: var(--i-netural-grey);
}

.specification-badge {
  @extend .text__bold;
  @extend .fs__16;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}