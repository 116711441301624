$primary: #0071d8;
$secondary: #1d3150;

$secondary-light: #1d3150ed;

$success: #70c387;
$success-light: #34E85C;
$info: #54E7ED;
$warning: #FBE909;
$warning-background: #fdcf53;
$danger: #f2470e;
$danger-light: #F4CD81;
$message: #020202;
$light: #f8f9fa;
$dark: #292929;
$white: #fefefe;
$black: #060f19;

$body-bg: #2F2F2F;
$body-text: #c6c9d2;
$sidebar-text: #d9dbe1;
$sidebar-bg: #3c3b3b;

$netural-grey: #535458;

$border-color: #8d8495;

$header-height: 85.75px;